import actions from './actions';

const initialState = {
  normalTests: [],
  loading: false,
  error: null,
  success: false,
  currentPage:1,
  page: 1,
  totalCount: 0,
  totalPages: 0,
};

const {
  NORMAL_TEST_FETCH_ALL_BEGIN,
  NORMAL_TEST_FETCH_ALL_SUCCESS,
  NORMAL_TEST_FETCH_ALL_ERR,
  NORMAL_TEST_FETCH_ONE_BEGIN,
  NORMAL_TEST_FETCH_ONE_SUCCESS,
  NORMAL_TEST_FETCH_ONE_ERR,
  NORMAL_TEST_CREATE_BEGIN,
  NORMAL_TEST_CREATE_SUCCESS,
  NORMAL_TEST_CREATE_ERR,
  NORMAL_TEST_UPDATE_BEGIN,
  NORMAL_TEST_UPDATE_SUCCESS,
  NORMAL_TEST_UPDATE_ERR,
  NORMAL_TEST_DELETE_BEGIN,
  NORMAL_TEST_DELETE_SUCCESS,
  NORMAL_TEST_DELETE_ERR,

  STUDENT_NORMAL_TEST_FETCH_ALL_BEGIN,
  STUDENT_NORMAL_TEST_FETCH_ALL_SUCCESS,
  STUDENT_NORMAL_TEST_FETCH_ALL_ERR,
} = actions;

const NormalTestReducer = (state = initialState, action) => {
  const { type, data, error  ,currentPage,totalCount,totalPages} = action;
  switch (type) {
    case NORMAL_TEST_FETCH_ALL_BEGIN:
    case NORMAL_TEST_FETCH_ONE_BEGIN:
    case NORMAL_TEST_CREATE_BEGIN:
    case NORMAL_TEST_UPDATE_BEGIN:
    case NORMAL_TEST_DELETE_BEGIN:
      return {
        ...state,
        loading: true,
        success: false,
      };

    case NORMAL_TEST_FETCH_ALL_SUCCESS:
      return {
        ...state,
        normalTests: data,
        currentPage,
        totalCount,
        totalPages,
        loading: false,
      };

    case NORMAL_TEST_FETCH_ONE_SUCCESS:
    case NORMAL_TEST_CREATE_SUCCESS:
    case NORMAL_TEST_UPDATE_SUCCESS:
    case NORMAL_TEST_DELETE_SUCCESS:
      return {
        ...state,
        normalTests: data,

        loading: false,
        success: true,
      };

    case NORMAL_TEST_FETCH_ALL_ERR:
    case NORMAL_TEST_FETCH_ONE_ERR:
    case NORMAL_TEST_CREATE_ERR:
    case NORMAL_TEST_UPDATE_ERR:
    case NORMAL_TEST_DELETE_ERR:
      return {
        ...state,
        error,
        loading: false,
      };

    // New cases for specific student reciting tests
    case STUDENT_NORMAL_TEST_FETCH_ALL_BEGIN:
      return {
        ...state,
        loading: true,
        success: false,
      };

    case STUDENT_NORMAL_TEST_FETCH_ALL_SUCCESS:
      return {
        ...state,
        recitingTests: data,
        loading: false,
      };


    case STUDENT_NORMAL_TEST_FETCH_ALL_ERR:
      return {
        ...state,
        error,
        loading: false,
      };

    default:
      return state;
  }
};

export default NormalTestReducer;
