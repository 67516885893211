import actions from './actions';

const initialState = {
  teachers: [],
  loading: false,
  error: null,
  page: 1,
  totalCount: 0,
  totalPages: 0,
  success:false
};

const {
  TEACHER_FETCH_ALL_BEGIN,
  TEACHER_FETCH_ALL_SUCCESS,
  TEACHER_FETCH_ALL_ERR,
  TEACHER_FETCH_ONE_BEGIN,
  TEACHER_FETCH_ONE_SUCCESS,
  TEACHER_FETCH_ONE_ERR,
  TEACHER_CREATE_BEGIN,
  TEACHER_CREATE_SUCCESS,
  TEACHER_CREATE_ERR,
  TEACHER_UPDATE_BEGIN,
  TEACHER_UPDATE_SUCCESS,
  TEACHER_UPDATE_ERR,
  TEACHER_DELETE_BEGIN,
  TEACHER_DELETE_SUCCESS,
  TEACHER_DELETE_ERR,
  RESET_TEACHER_SUCCESS
} = actions;

const TeacherReducer = (state = initialState, action) => {
  const { type, data, error } = action;
  switch (type) {
    case RESET_TEACHER_SUCCESS:
      return{...state,success:false};

    case TEACHER_FETCH_ALL_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
        page: action.page
      };

    case TEACHER_FETCH_ONE_BEGIN:
    case TEACHER_CREATE_BEGIN:
    case TEACHER_UPDATE_BEGIN:
    case TEACHER_DELETE_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case TEACHER_FETCH_ALL_SUCCESS:
      return {
        ...state,
        loading: false,
        teachers: action.data,
        page: action.currentPage,
        totalCount: action.totalCount,
        totalPages: action.totalPages,
      };
    case TEACHER_FETCH_ONE_SUCCESS:
    case TEACHER_CREATE_SUCCESS:
    case TEACHER_UPDATE_SUCCESS:
    case TEACHER_DELETE_SUCCESS:


      return {
        ...state,
        teachers: data,
        loading: false,
        success: action.success, // Use success flag from action


      };

    case TEACHER_FETCH_ALL_ERR:
    case TEACHER_FETCH_ONE_ERR:
    case TEACHER_CREATE_ERR:
    case TEACHER_UPDATE_ERR:
    case TEACHER_DELETE_ERR:
      return {
        ...state,
        error:"Wtf",
        loading: false,
      };

    default:
      return state;
  }
};

export default TeacherReducer;
