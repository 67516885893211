import { TEACHER_LIST_SUCCESS, TEACHER_LIST_FAIL } from './actions';

const initialState = {
  teachers: [],
  error: null,
};

export const TeachersListReducer = (state = initialState, action) => {
  switch (action.type) {
    case TEACHER_LIST_SUCCESS:
      return { ...state, teachers: action.payload, error: null };
    case TEACHER_LIST_FAIL:
      return { ...state, error: action.payload };
    default:
      return state;
  }
};
