import React, { lazy } from 'react';
import { Routes, Route ,Navigate} from 'react-router-dom';

const Students = lazy(() => import('../../container/students/Students'));
const SingleStudentView = lazy(() => import('../../container/students/SingleStudentPage'));

const NotFound = lazy(() => import('../../container/pages/404'));
const AddUser = lazy(() => import('../../container/students/AddStudent'));

function PagesRoute() {
  return (
    <Routes>
      <Route path="/" element={<Students />}/>
      <Route path="add-student/*" element={<AddUser />} />
      <Route path="single/:id/*" element={<SingleStudentView />}  />
      <Route path="*" element={<NotFound />} />


    </Routes>
  );
}

export default PagesRoute;
