import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/analytics';
import 'firebase/compat/storage';
import rootReducer from './rootReducers';

const store = createStore(
  rootReducer,
  compose(
    applyMiddleware(thunk.withExtraArgument()),
  ),
);



export default store;
