import { ConfigProvider } from 'antd';
import 'antd/dist/antd.less';
import React, { useEffect, useState, lazy } from 'react';
import { Provider, useSelector, useDispatch } from 'react-redux';
import { BrowserRouter as Router, Navigate, Route, Routes } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import ProtectedRoute from './components/utilities/protectedRoute';
import config from './config/config';
import store from './redux/store';
import Admin from './routes/admin';
import Auth from './routes/auth';
import './static/css/style.css';
import { checkAuth } from './redux/authentication/actionCreator'; // Import checkAuth

const NotFound = lazy(() => import('./container/pages/404'));

const { themeColor } = config;

function ProviderConfig() {
  const dispatch = useDispatch(); // Get dispatch from useDispatch

  const { rtl, isLoggedIn, topMenu, mainContent } = useSelector((state) => ({
    rtl: state.ChangeLayoutMode.rtlData,
    topMenu: state.ChangeLayoutMode.topMenu,
    mainContent: state.ChangeLayoutMode.mode,
    isLoggedIn: state.auth.login,
  }));

  const [path, setPath] = useState(window.location.pathname);

  useEffect(() => {
    let unmounted = false;
    if (!unmounted) {
      setPath(window.location.pathname);
    }

    return () => {
      unmounted = true;
    };
  }, [setPath]);

  useEffect(() => {
    // Dispatch checkAuth on mount to verify user authentication
    dispatch(checkAuth());
  }, [dispatch]);
  return (
    <ConfigProvider direction={rtl ? 'rtl' : 'ltr'}>
      <ThemeProvider theme={{ ...themeColor, rtl, topMenu, mainContent }}>
        <Router basename={process.env.REACT_PUBLIC_URL}>
          {isLoggedIn ? (
            <Routes>
              {path === "/" && <Route path="/" element={<Navigate to="/admin" />} />}

              <Route path="/admin/*" element={<ProtectedRoute path="/*" Component={Admin} />} />
              <Route path="*" element={<NotFound />} />
            </Routes>
          ) : (
            <Routes>
              <Route path="/*" element={<Auth />} />
            </Routes>
          )}
          
          
        </Router>
      </ThemeProvider>
    </ConfigProvider>
  );
}

function App() {
  return (
    <Provider store={store}>
      <ProviderConfig />
    </Provider>
  );
}

export default App;
