import React, { lazy } from 'react';
import { Routes, Route } from 'react-router-dom';

// const BlankPage = lazy(() => import('../../container/profile/settings/Settings'));
const Home = lazy(() => import('../../container/home/Home'));
// const Quran =  lazy(()=> import('../../container/quran/quran'))


function PagesRoute() {
  return (
    <Routes>
      <Route index element={<Home />} />
      <Route path="starter" element={<Home />} />
      {/* <Route path="quran" element={<Quran />} /> */}

    </Routes>
  );
}

export default PagesRoute;
