import UilAngleDown from '@iconscout/react-unicons/icons/uil-angle-down';
import UilSetting from '@iconscout/react-unicons/icons/uil-setting';
import UilSignout from '@iconscout/react-unicons/icons/uil-signout';
import UilUser from '@iconscout/react-unicons/icons/uil-user';
import { Avatar } from 'antd';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';

import { InfoWraper, NavAuth, UserDropDwon } from './auth-info-style';
import { logOut, checkAuth } from '../../../redux/authentication/actionCreator'; // Import checkAuth
import Heading from '../../heading/heading';
import { Popover } from '../../popup/popup';

const AuthInfo = React.memo(() => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { i18n } = useTranslation();
  
  const [flag, setFlag] = useState('ar');

  const { user, login } = useSelector((state) => state.auth);

  useEffect(() => {
    // Check authentication if user is not available
    if (!user) {
      dispatch(checkAuth());
    }
  }, [dispatch, user]);

  const handleSignOut = (e) => {
    e.preventDefault();
    dispatch(logOut(() => navigate('/')));
  };

  const handleLanguageChange = (value, e) => {
    e.preventDefault();
    setFlag(value);
    i18n.changeLanguage(value);
  };

  const languageOptions = [
    { code: 'en', label: 'English', img: 'en.png' },
    { code: 'esp', label: 'Spanish', img: 'esp.png' },
    { code: 'ar', label: 'Arabic', img: 'ar.png' }
  ];

  const userContent = (
    <UserDropDwon>
      <div className="user-dropdwon">
        <figure className="user-dropdwon__info">
          <img src={require('../../../static/img/avatar/chat-auth.png')} alt="Avatar" />
          <figcaption>
            <Heading as="h5">{`${user?.first_name || 'First Name'} ${user?.last_name || 'Last Name'}`}</Heading>
            <p>{user?.username || 'Username'}</p>
          </figcaption>
        </figure>
        <ul className="user-dropdwon__links">
          <li><Link to="#"><UilUser /> Profile</Link></li>
          <li><Link to="#"><UilSetting /> Settings</Link></li>
        </ul>
        <Link className="user-dropdwon__bottomAction" onClick={handleSignOut} to="#">
          <UilSignout /> Sign Out
        </Link>
      </div>
    </UserDropDwon>
  );

  const country = (
    <NavAuth>
      {languageOptions.map(({ code, label, img }) => (
        <Link key={code} onClick={(e) => handleLanguageChange(code, e)} to="#">
          <img src={require(`../../../static/img/flag/${img}`)} alt={label} />
          <span>{label}</span>
        </Link>
      ))}
    </NavAuth>
  );

  return (
    <InfoWraper>
      <div className="sytnix-nav-actions__item sytnix-nav-actions__author">
        <Popover placement="bottomRight" content={userContent} action="click">
          <Link to="#" className="sytnix-nav-action-link">
            <Avatar src={user?.avatar || 'https://cdn0.iconfinder.com/data/icons/user-pictures/100/matureman1-512.png'} />
            <span className="sytnix-nav-actions__author--name">{user?.username || 'Username'}</span>
            <UilAngleDown />
          </Link>
        </Popover>
      </div>
    </InfoWraper>
  );
});

export default AuthInfo;
