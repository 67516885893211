import actions from './actions';

const initialState = {
  students: [],
  loading: false,
  error: null,
  page: 1,
  totalCount: 0,
  totalPages: 0,
  success: false

};

const {
  STUDENT_FETCH_ALL_BEGIN,
  STUDENT_FETCH_ALL_SUCCESS,
  STUDENT_FETCH_ALL_ERR,
  STUDENT_FETCH_ONE_BEGIN,
  STUDENT_FETCH_ONE_SUCCESS,
  STUDENT_FETCH_ONE_ERR,
  STUDENT_CREATE_BEGIN,
  STUDENT_CREATE_SUCCESS,
  STUDENT_CREATE_ERR,
  STUDENT_UPDATE_BEGIN,
  STUDENT_UPDATE_SUCCESS,
  STUDENT_UPDATE_ERR,
  STUDENT_DELETE_BEGIN,
  STUDENT_DELETE_SUCCESS,
  STUDENT_DELETE_ERR,
  RESET_STUDENT_SUCCESS
} = actions;

const StudentReducer = (state = initialState, action) => {
  const { type, data, error } = action;
  switch (type) {
    case RESET_STUDENT_SUCCESS:
      return{...state,success:false};
    case STUDENT_FETCH_ALL_BEGIN:
    case STUDENT_FETCH_ONE_BEGIN:
    case STUDENT_CREATE_BEGIN:
    case STUDENT_UPDATE_BEGIN:
    case STUDENT_DELETE_BEGIN:
      return {
        ...state,
        loading: true,
        success: false, // Reset success on begin
        page: action.page


      };

    case STUDENT_FETCH_ALL_SUCCESS:
      return {
        ...state,
        students: data,
        loading: false,
        page: action.currentPage,
        totalCount: action.totalCount,
        totalPages: action.totalPages,
      };

    case STUDENT_FETCH_ONE_SUCCESS:
    case STUDENT_CREATE_SUCCESS:
    case STUDENT_UPDATE_SUCCESS:
    case STUDENT_DELETE_SUCCESS:
      return {
        ...state,
        students: data,
        loading: false, 
        success: true, // Reset success on begin

      };

    case STUDENT_FETCH_ALL_ERR:
    case STUDENT_FETCH_ONE_ERR:
    case STUDENT_CREATE_ERR:
    case STUDENT_UPDATE_ERR:
    case STUDENT_DELETE_ERR:
      return {
        ...state,
        error,
        loading: false,
      };

    default:
      return state;
  }
};

export default StudentReducer;
