const actions = {
  RECITING_TEST_FETCH_ALL_BEGIN: 'RECITING_TEST_FETCH_ALL_BEGIN',
  RECITING_TEST_FETCH_ALL_SUCCESS: 'RECITING_TEST_FETCH_ALL_SUCCESS',
  RECITING_TEST_FETCH_ALL_ERR: 'RECITING_TEST_FETCH_ALL_ERR',

  RECITING_TEST_FETCH_ONE_BEGIN: 'RECITING_TEST_FETCH_ONE_BEGIN',
  RECITING_TEST_FETCH_ONE_SUCCESS: 'RECITING_TEST_FETCH_ONE_SUCCESS',
  RECITING_TEST_FETCH_ONE_ERR: 'RECITING_TEST_FETCH_ONE_ERR',

  RECITING_TEST_CREATE_BEGIN: 'RECITING_TEST_CREATE_BEGIN',
  RECITING_TEST_CREATE_SUCCESS: 'RECITING_TEST_CREATE_SUCCESS',
  RECITING_TEST_CREATE_ERR: 'RECITING_TEST_CREATE_ERR',

  RECITING_TEST_UPDATE_BEGIN: 'RECITING_TEST_UPDATE_BEGIN',
  RECITING_TEST_UPDATE_SUCCESS: 'RECITING_TEST_UPDATE_SUCCESS',
  RECITING_TEST_UPDATE_ERR: 'RECITING_TEST_UPDATE_ERR',

  RECITING_TEST_DELETE_BEGIN: 'RECITING_TEST_DELETE_BEGIN',
  RECITING_TEST_DELETE_SUCCESS: 'RECITING_TEST_DELETE_SUCCESS',
  RECITING_TEST_DELETE_ERR: 'RECITING_TEST_DELETE_ERR',

  STUDENT_RECITING_TEST_FETCH_ALL_BEGIN: 'STUDENT_RECITING_TEST_FETCH_ALL_BEGIN',
  STUDENT_RECITING_TEST_FETCH_ALL_SUCCESS: 'STUDENT_RECITING_TEST_FETCH_ALL_SUCCESS',
  STUDENT_RECITING_TEST_FETCH_ALL_ERR: 'STUDENT_RECITING_TEST_FETCH_ALL_ERR',

  STUDENT_RECITING_TEST_CREATE_BEGIN: 'STUDENT_RECITING_TEST_CREATE_BEGIN',
  STUDENT_RECITING_TEST_CREATE_SUCCESS: 'STUDENT_RECITING_TEST_CREATE_SUCCESS',
  STUDENT_RECITING_TEST_CREATE_ERR: 'STUDENT_RECITING_TEST_CREATE_ERR',

  // Action creators for fetching reciting tests for a specific student
  fetchAllStudentRecitingTestsBegin: (studentId) => ({
    type: actions.STUDENT_RECITING_TEST_FETCH_ALL_BEGIN,
    studentId,
  }),

  fetchAllStudentRecitingTestsSuccess: (data, studentId) => ({
    type: actions.STUDENT_RECITING_TEST_FETCH_ALL_SUCCESS,
    data,
    studentId,
  }),

  fetchAllStudentRecitingTestsErr: (error, studentId) => ({
    type: actions.STUDENT_RECITING_TEST_FETCH_ALL_ERR,
    error,
    studentId,
  }),

  // Action creators for creating a reciting test for a specific student
  createStudentRecitingTestBegin: (studentId) => ({
    type: actions.STUDENT_RECITING_TEST_CREATE_BEGIN,
    studentId,
  }),

  createStudentRecitingTestSuccess: (data, studentId) => ({
    type: actions.STUDENT_RECITING_TEST_CREATE_SUCCESS,
    data,
    studentId,
    success: true,
  }),

  createStudentRecitingTestErr: (error, studentId) => ({
    type: actions.STUDENT_RECITING_TEST_CREATE_ERR,
    error,
    studentId,
  }),



  fetchAllRecitingTestsBegin: () => ({
    type: actions.RECITING_TEST_FETCH_ALL_BEGIN,
  }),

  fetchAllRecitingTestsSuccess: (data, currentPage, totalCount, totalPages) => ({
    type: actions.RECITING_TEST_FETCH_ALL_SUCCESS,
    data,
    currentPage,
    totalCount,
    totalPages
  }),

  fetchAllRecitingTestsErr: (error) => ({
    type: actions.RECITING_TEST_FETCH_ALL_ERR,
    error,
  }),

  fetchOneRecitingTestBegin: () => ({
    type: actions.RECITING_TEST_FETCH_ONE_BEGIN,
  }),

  fetchOneRecitingTestSuccess: (data) => ({
    type: actions.RECITING_TEST_FETCH_ONE_SUCCESS,
    data,
  }),

  fetchOneRecitingTestErr: (error) => ({
    type: actions.RECITING_TEST_FETCH_ONE_ERR,
    error,
  }),

  createRecitingTestBegin: () => ({
    type: actions.RECITING_TEST_CREATE_BEGIN,
  }),

  createRecitingTestSuccess: (data) => ({
    type: actions.RECITING_TEST_CREATE_SUCCESS,
    data,
    success: true
  }),

  createRecitingTestErr: (error) => ({
    type: actions.RECITING_TEST_CREATE_ERR,
    error,
  }),

  updateRecitingTestBegin: () => ({
    type: actions.RECITING_TEST_UPDATE_BEGIN,
  }),

  updateRecitingTestSuccess: (data) => ({
    type: actions.RECITING_TEST_UPDATE_SUCCESS,
    data,
  }),

  updateRecitingTestErr: (error) => ({
    type: actions.RECITING_TEST_UPDATE_ERR,
    error,
  }),

  deleteRecitingTestBegin: () => ({
    type: actions.RECITING_TEST_DELETE_BEGIN,
  }),

  deleteRecitingTestSuccess: (data) => ({
    type: actions.RECITING_TEST_DELETE_SUCCESS,
    data,
  }),

  deleteRecitingTestErr: (error) => ({
    type: actions.RECITING_TEST_DELETE_ERR,
    error,
  }),
};

export default actions;
