import { Spin } from 'antd';
import React, { lazy, Suspense, useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import Pages from './pages';
import withAdminLayout from '../../layout/withAdminLayout';
import Users from './teachers';
import Students from './students';
import SuccessPage from '../../container/teachers/components/SuccessPage';
import SuccessPageStudent from '../../container/students/SuccessPage';


const Admin = React.memo(() => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <Suspense
      fallback={
        <div className="spin">
          <Spin />
        </div>
      }
    >
      <Routes>
        <Route index path="/*" element={<Pages />} />
        <Route path="pages/*" element={<Pages />} />
        <Route path="teachers/*" element={<Users />}  />
        <Route path="students/*" element={<Students />} />
        <Route path="/successteacher" element={<SuccessPage />} />
        <Route path="/successtudent" element={<SuccessPageStudent />} />


      </Routes>
    </Suspense>
  );
});

export default withAdminLayout(Admin);
