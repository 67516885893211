const actions = {
  RESET_TEACHER_SUCCESS:"RESET_TEACHER_SUCCESS",
  TEACHER_FETCH_ALL_BEGIN: 'TEACHER_FETCH_ALL_BEGIN',
  TEACHER_FETCH_ALL_SUCCESS: 'TEACHER_FETCH_ALL_SUCCESS',
  TEACHER_FETCH_ALL_ERR: 'TEACHER_FETCH_ALL_ERR',

  TEACHER_FETCH_ONE_BEGIN: 'TEACHER_FETCH_ONE_BEGIN',
  TEACHER_FETCH_ONE_SUCCESS: 'TEACHER_FETCH_ONE_SUCCESS',
  TEACHER_FETCH_ONE_ERR: 'TEACHER_FETCH_ONE_ERR',

  TEACHER_CREATE_BEGIN: 'TEACHER_CREATE_BEGIN',
  TEACHER_CREATE_SUCCESS: 'TEACHER_CREATE_SUCCESS',
  TEACHER_CREATE_ERR: 'TEACHER_CREATE_ERR',

  TEACHER_UPDATE_BEGIN: 'TEACHER_UPDATE_BEGIN',
  TEACHER_UPDATE_SUCCESS: 'TEACHER_UPDATE_SUCCESS',
  TEACHER_UPDATE_ERR: 'TEACHER_UPDATE_ERR',

  TEACHER_DELETE_BEGIN: 'TEACHER_DELETE_BEGIN',
  TEACHER_DELETE_SUCCESS: 'TEACHER_DELETE_SUCCESS',
  TEACHER_DELETE_ERR: 'TEACHER_DELETE_ERR',
  

  resetTeacherSuccess: () => ({
    type: actions.RESET_TEACHER_SUCCESS,
    success:false

  }),

  fetchAllTeachersBegin: (page = 1) => ({
    type: actions.TEACHER_FETCH_ALL_BEGIN,
    page
  }),

  fetchAllTeachersSuccess: (data, currentPage, totalCount, totalPages) => ({
    type: actions.TEACHER_FETCH_ALL_SUCCESS,
    data,
    currentPage,
    totalCount,
    totalPages
  }),

  fetchAllTeachersErr: (error) => ({
    type: actions.TEACHER_FETCH_ALL_ERR,
    error,
  }),

  fetchOneTeacherBegin: () => ({
    type: actions.TEACHER_FETCH_ONE_BEGIN,
  }),

  fetchOneTeacherSuccess: (data) => ({
    type: actions.TEACHER_FETCH_ONE_SUCCESS,
    data,
  }),

  fetchOneTeacherErr: (error) => ({
    type: actions.TEACHER_FETCH_ONE_ERR,
    error,
  }),

  createTeacherBegin: () => ({
    type: actions.TEACHER_CREATE_BEGIN,
  }),

  createTeacherSuccess: (data) => ({
    type: actions.TEACHER_CREATE_SUCCESS,
    data,
    success:true
    
  }),

  createTeacherErr: (error) => ({
    type: actions.TEACHER_CREATE_ERR,
    error,
  }),

  updateTeacherBegin: () => ({
    type: actions.TEACHER_UPDATE_BEGIN,
  }),

  updateTeacherSuccess: (data) => ({
    type: actions.TEACHER_UPDATE_SUCCESS,
    data,
  }),

  updateTeacherErr: (error) => ({
    type: actions.TEACHER_UPDATE_ERR,
    error,
  }),

  deleteTeacherBegin: () => ({
    type: actions.TEACHER_DELETE_BEGIN,
  }),

  deleteTeacherSuccess: (data) => ({
    type: actions.TEACHER_DELETE_SUCCESS,
    data,
  }),

  deleteTeacherErr: (error) => ({
    type: actions.TEACHER_DELETE_ERR,
    error,
  }),
};

export default actions;
