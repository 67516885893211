const actions = {
    RESET_STUDENT_SUCCESS:"RESET_STUDENT_SUCCESS",

    STUDENT_FETCH_ALL_BEGIN: 'STUDENT_FETCH_ALL_BEGIN',
    STUDENT_FETCH_ALL_SUCCESS: 'STUDENT_FETCH_ALL_SUCCESS',
    STUDENT_FETCH_ALL_ERR: 'STUDENT_FETCH_ALL_ERR',
  
    STUDENT_FETCH_ONE_BEGIN: 'STUDENT_FETCH_ONE_BEGIN',
    STUDENT_FETCH_ONE_SUCCESS: 'STUDENT_FETCH_ONE_SUCCESS',
    STUDENT_FETCH_ONE_ERR: 'STUDENT_FETCH_ONE_ERR',
  
    STUDENT_CREATE_BEGIN: 'STUDENT_CREATE_BEGIN',
    STUDENT_CREATE_SUCCESS: 'STUDENT_CREATE_SUCCESS',
    STUDENT_CREATE_ERR: 'STUDENT_CREATE_ERR',
  
    STUDENT_UPDATE_BEGIN: 'STUDENT_UPDATE_BEGIN',
    STUDENT_UPDATE_SUCCESS: 'STUDENT_UPDATE_SUCCESS',
    STUDENT_UPDATE_ERR: 'STUDENT_UPDATE_ERR',
  
    STUDENT_DELETE_BEGIN: 'STUDENT_DELETE_BEGIN',
    STUDENT_DELETE_SUCCESS: 'STUDENT_DELETE_SUCCESS',
    STUDENT_DELETE_ERR: 'STUDENT_DELETE_ERR',
  
    resetStudentSucess: () => ({
      type: actions.RESET_STUDENT_SUCCESS,
      success:false
    }),
  
    fetchAllStudentsBegin: (page=1) => (
      {
      type: actions.STUDENT_FETCH_ALL_BEGIN,
      page
    }),
  
    fetchAllStudentsSuccess: (data, currentPage, totalCount, totalPages) => ({
      type: actions.STUDENT_FETCH_ALL_SUCCESS,
      data,
      currentPage,
      totalCount,
      totalPages
    }),
  
    fetchAllStudentsErr: (error) => ({
      type: actions.STUDENT_FETCH_ALL_ERR,
      error,
    }),
  
    fetchOneStudentBegin: () => ({
      type: actions.STUDENT_FETCH_ONE_BEGIN,
    }),
  
    fetchOneStudentSuccess: (data) => ({
      type: actions.STUDENT_FETCH_ONE_SUCCESS,
      data,
    }),
  
    fetchOneStudentErr: (error) => ({
      type: actions.STUDENT_FETCH_ONE_ERR,
      error,
    }),
  
    createStudentBegin: () => ({
      type: actions.STUDENT_CREATE_BEGIN,
    }),
  
    createStudentSuccess: (data) => ({
      type: actions.STUDENT_CREATE_SUCCESS,
      data,
      success:true

    }),
  
    createStudentErr: (error) => ({
      type: actions.STUDENT_CREATE_ERR,
      error,
    }),
  
    updateStudentBegin: () => ({
      type: actions.STUDENT_UPDATE_BEGIN,
    }),
  
    updateStudentSuccess: (data) => ({
      type: actions.STUDENT_UPDATE_SUCCESS,
      data,
    }),
  
    updateStudentErr: (error) => ({
      type: actions.STUDENT_UPDATE_ERR,
      error,
    }),
  
    deleteStudentBegin: () => ({
      type: actions.STUDENT_DELETE_BEGIN,
    }),
  
    deleteStudentSuccess: (data) => ({
      type: actions.STUDENT_DELETE_SUCCESS,
      data,
    }),
  
    deleteStudentErr: (error) => ({
      type: actions.STUDENT_DELETE_ERR,
      error,
    }),
  };
  
  export default actions;
  