import React from 'react';
import { Row, Col, Button, Typography, Card } from 'antd';
import { useNavigate } from 'react-router-dom';
import { UilCheck } from '@iconscout/react-unicons';

const { Title, Text } = Typography;

const SuccessPage = () => {
  const navigate = useNavigate();

  const handleGoToTeam = () => {
    navigate('/admin/teachers/team/');
  };

  return (
    <Row justify="center" style={{ width: '100%', padding: '20px' }}>
      <Col xl={20} xs={24}>
        <Card
          bordered={false}
          style={{
            borderRadius: '20px',
            textAlign: 'center',
            padding: '20px',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)'
          }}
        >
          <span className="icon-success" style={{ fontSize: '48px', color: '#52c41a' }}>
            <UilCheck />
          </span>
          <Title level={3}>Teacher Added Successfully!</Title>
          <Text>Congratulations! The new teacher has been added successfully.</Text>
          <div style={{ marginTop: '20px' }}>
            <Button type="primary" onClick={handleGoToTeam}>
              Go to Team Page
            </Button>
          </div>
        </Card>
      </Col>
    </Row>
  );
};

export default SuccessPage;
