import { UilCircle, UilUsersAlt, UilHome, } from '@iconscout/react-unicons';

import { Menu } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import Cookies from 'js-cookie';
import UilEllipsisV from '@iconscout/react-unicons/icons/uil-ellipsis-v';
import propTypes from 'prop-types';

function MenuItems({ toggleCollapsed }) {
  const { t } = useTranslation();

  function getItem(label, key, icon, children, type) {
    return {
      key,
      icon,
      children,
      label,
      type,
    };
  }

  const { topMenu, userRole } = useSelector((state) => {
    return {
      topMenu: state.ChangeLayoutMode.topMenu,
      userRole: state.auth.user?.role, // Assuming userRole is stored in state.auth.user.role

    };
  });

  const path = '/admin';
  const pathName = window.location.pathname;
  const pathArray = pathName && pathName !== '/' ? pathName.split(path) : [];
  const mainPath = pathArray.length > 1 ? pathArray[1] : '';
  const mainPathSplit = mainPath.split('/');

  const [openKeys, setOpenKeys] = React.useState(
    !topMenu ? [`${mainPathSplit.length > 2 ? mainPathSplit[1] : 'dashboard'}`] : [],
  );

  const onOpenChange = (keys) => {
    setOpenKeys(keys[keys.length - 1] !== 'recharts' ? [keys.length && keys[keys.length - 1]] : keys);
  };

  const onClick = (item) => {
    if (item.keyPath.length === 1) setOpenKeys([]);
  };

  const items = [
    getItem(
      <NavLink onClick={toggleCollapsed} to={`${path}/pages/starter`}>
        {t('page')} {t('home')}
      </NavLink>,
      'starter',
      !topMenu && (
        <NavLink className="menuItem-iocn" to={`${path}/pages/starter`}>
          <UilHome />
        </NavLink>
      ),
    ),

    // getItem(
    //   <NavLink onClick={toggleCollapsed} to={`${path}/pages/quran`}>
    //     {t('quran')}
    //   </NavLink>,
    //   'note',
    //   !topMenu && (
    //     <NavLink className="menuItem-iocn" to={`${path}/pages/quran`}>
    //       <UilClipboardAlt />
    //     </NavLink>
    //   ),
    // ),
    getItem(t('students'), 'students-menu', !topMenu && <UilUsersAlt />, [
      getItem(
        <NavLink onClick={toggleCollapsed} to={`${path}/students/`}>
          {t('students')}
        </NavLink>,
        'students',
        null,
      ),
      getItem(
        <NavLink onClick={toggleCollapsed} to={`${path}/students/add-student/`}>
          {t('add')} {t('student')}
        </NavLink>,
        'addStudent',
        null,
      ),
    ]),
    
,
  ];
  const isAdmin = Cookies.get('admin') === 'true';
  if (isAdmin) {
    items.splice(
      1, 0,

      getItem(t('teachers'), 'teachers', !topMenu && <UilUsersAlt />, [
      getItem(
        <NavLink onClick={toggleCollapsed} to={`${path}/teachers/team`}>
          {t('team')}
        </NavLink>,
        'team',
        null,
      ),
      getItem(
        <NavLink onClick={toggleCollapsed} to={`${path}/teachers/add-teacher/info`}>
          {t('add')} {t('teacher')}
        </NavLink>,
        'addUser',
        null,
      ),

    ])
    );
  }
  return (
    <Menu
      onOpenChange={onOpenChange}
      onClick={onClick}
      mode={!topMenu || window.innerWidth <= 991 ? 'inline' : 'horizontal'}
      // // eslint-disable-next-line no-nested-ternary
      defaultSelectedKeys={
        topMenu ? [] : [
          `${mainPathSplit.length === 1 ? 'home' : mainPathSplit.length === 2 ? mainPathSplit[1] : mainPathSplit[2]
          }`,
        ]
      }
      defaultOpenKeys={topMenu ? [] : [`${mainPathSplit.length > 2 ? mainPathSplit[1] : 'dashboard'}`]}
      overflowedIndicator={<UilEllipsisV />}
      openKeys={openKeys}
      items={userRole == "student" ? [] : items}
    />
  );
}

MenuItems.propTypes = {
  toggleCollapsed: propTypes.func,
};

export default MenuItems;
