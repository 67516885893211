import { combineReducers } from 'redux';
import authReducer from './authentication/reducers';
import ChangeLayoutMode from './themeLayout/reducers';
import StudentReducer from './students/reducers'
import TeacherReducer from './teachers/reducers';
import { TeachersListReducer } from './teachers list/reducers'
import RecitingTestReducer from './reciting test/reducers';
import NormalTestReducer from './normal test/reducers';
import homepageReducer from './home/reducers';


const rootReducers = combineReducers({
  auth: authReducer,
  ChangeLayoutMode,
  students: StudentReducer,
  teachers: TeacherReducer,
  teacherslist: TeachersListReducer,
  recitingTests: RecitingTestReducer,
  normalTests: NormalTestReducer,
  home: homepageReducer,

});


export default rootReducers;

