import Cookies from 'js-cookie';
import actions from './actions';
import { DataService } from '../../config/dataService/dataService';

const { loginBegin, loginSuccess, loginErr, logoutBegin, logoutSuccess, logoutErr } = actions;

const login = (values, callback) => {
  return async (dispatch) => {
    dispatch(loginBegin());
    try {
      const response = await DataService.post('/login/', values);

      if (response.status != 200) {

        dispatch(loginErr(["Wrong Crednitals"]));
      } else {
        Cookies.set('logedIn', true);
        Cookies.set('admin', response.data.admin === true);

        const { user, admin } = response.data; // Assume API returns user object and admin status

        dispatch(loginSuccess({ user, admin }));

        callback();
      }
    } catch (err) {
      dispatch(loginErr(err));
    }
  };
};


// Action to check if the user is authenticated
const checkAuth = () => {
  return async (dispatch) => {
    dispatch(loginBegin()); // Optional: Set loading state while checking auth

    // Check if sessionid and csrftoken cookies are present
    const csrftoken = Cookies.get('csrftoken');

    if (!csrftoken) {

      logOut();
      return; // Exit if cookies are not presents
    }
    try {
      // Call the API to verify authentication status
      const response = await DataService.get('/check-auth/');
      if (response.status === 200 && response.data.authenticated) {
        // Set cookies for session persistence
        Cookies.set('logedIn', true);
        // Dispatch success with user and admin details
        const { user } = response.data; // Assume API returns user object and admin status

        user.role = response.data.role;

        Cookies.set('admin', response.data.role === "admin");

        dispatch(loginSuccess({ user, admin: false }));
      } else {
        // Handle case where user is not authenticated
        dispatch(loginErr("User is not authenticated."));
      }
    } catch (err) {
      // Handle any errors that occur during the API call
      dispatch(loginErr(err));
    }
  };
};




const logOut = (callback) => {
  return async (dispatch) => {
    dispatch(logoutBegin());

    try {

      const response = await DataService.post('/logout/',);
      const allCookies = Cookies.get(); // Get all cookies
      Object.keys(allCookies).forEach(cookieName => {
        Cookies.remove(cookieName); // Remove each cookie
      });
      dispatch(logoutSuccess(false));
      callback();
    } catch (err) {
      dispatch(logoutErr(err));
    }
  };
};

export { login, logOut, checkAuth };
