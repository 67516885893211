import React, { lazy } from 'react';
import { Routes, Route ,Navigate} from 'react-router-dom';

const AddUser = lazy(() => import('../../container/teachers/AddTeacher'));
const Team = lazy(() => import('../../container/teachers/Team'));
const NotFound = lazy(() => import('../../container/pages/404'));

function PagesRoute() {
  return (
    <Routes>
      <Route path="/*" element={<Navigate to="team" />} />
      <Route path="add-teacher/*" element={<AddUser />} />
      <Route path="team" element={<Team />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

export default PagesRoute;
