const actions = {
    NORMAL_TEST_FETCH_ALL_BEGIN: 'NORMAL_TEST_FETCH_ALL_BEGIN',
    NORMAL_TEST_FETCH_ALL_SUCCESS: 'NORMAL_TEST_FETCH_ALL_SUCCESS',
    NORMAL_TEST_FETCH_ALL_ERR: 'NORMAL_TEST_FETCH_ALL_ERR',
  
    NORMAL_TEST_FETCH_ONE_BEGIN: 'NORMAL_TEST_FETCH_ONE_BEGIN',
    NORMAL_TEST_FETCH_ONE_SUCCESS: 'NORMAL_TEST_FETCH_ONE_SUCCESS',
    NORMAL_TEST_FETCH_ONE_ERR: 'NORMAL_TEST_FETCH_ONE_ERR',
  
    NORMAL_TEST_CREATE_BEGIN: 'NORMAL_TEST_CREATE_BEGIN',
    NORMAL_TEST_CREATE_SUCCESS: 'NORMAL_TEST_CREATE_SUCCESS',
    NORMAL_TEST_CREATE_ERR: 'NORMAL_TEST_CREATE_ERR',
  
    NORMAL_TEST_UPDATE_BEGIN: 'NORMAL_TEST_UPDATE_BEGIN',
    NORMAL_TEST_UPDATE_SUCCESS: 'NORMAL_TEST_UPDATE_SUCCESS',
    NORMAL_TEST_UPDATE_ERR: 'NORMAL_TEST_UPDATE_ERR',
  
    NORMAL_TEST_DELETE_BEGIN: 'NORMAL_TEST_DELETE_BEGIN',
    NORMAL_TEST_DELETE_SUCCESS: 'NORMAL_TEST_DELETE_SUCCESS',
    NORMAL_TEST_DELETE_ERR: 'NORMAL_TEST_DELETE_ERR',

    STUDENT_NORMAL_TEST_FETCH_ALL_BEGIN: 'STUDENT_NORMAL_TEST_FETCH_ALL_BEGIN',
    STUDENT_NORMAL_TEST_FETCH_ALL_SUCCESS: 'STUDENT_NORMAL_TEST_FETCH_ALL_SUCCESS',
    STUDENT_NORMAL_TEST_FETCH_ALL_ERR: 'STUDENT_NORMAL_TEST_FETCH_ALL_ERR',
  
      // Action creators for fetching normal tests for a specific student
  fetchAllStudentNormalTestsBegin: (studentId) => ({
    type: actions.STUDENT_NORMAL_TEST_FETCH_ALL_BEGIN,
    studentId,
  }),

  fetchAllStudentNormalTestsSuccess: (data, studentId) => ({
    type: actions.STUDENT_NORMAL_TEST_FETCH_ALL_SUCCESS,
    data,
    studentId,
  }),

  fetchAllStudentNormalTestsErr: (error, studentId) => ({
    type: actions.STUDENT_NORMAL_TEST_FETCH_ALL_ERR,
    error,
    studentId,
  }),

    fetchAllNormalTestsBegin: () => ({
      type: actions.NORMAL_TEST_FETCH_ALL_BEGIN,
    }),
  
    fetchAllNormalTestsSuccess: (data, currentPage, totalCount, totalPages) => ({
      type: actions.NORMAL_TEST_FETCH_ALL_SUCCESS,
      data,
      currentPage,
      totalCount,
      totalPages
    }),
  
    fetchAllNormalTestsErr: (error) => ({
      type: actions.NORMAL_TEST_FETCH_ALL_ERR,
      error,
    }),
  
    fetchOneNormalTestBegin: () => ({
      type: actions.NORMAL_TEST_FETCH_ONE_BEGIN,
    }),
  
    fetchOneNormalTestSuccess: (data) => ({
      type: actions.NORMAL_TEST_FETCH_ONE_SUCCESS,
      data,
    }),
  
    fetchOneNormalTestErr: (error) => ({
      type: actions.NORMAL_TEST_FETCH_ONE_ERR,
      error,
    }),
  
    createNormalTestBegin: () => ({
      type: actions.NORMAL_TEST_CREATE_BEGIN,
    }),
  
    createNormalTestSuccess: (data) => ({
      type: actions.NORMAL_TEST_CREATE_SUCCESS,
      data,
      success: true
    }),
  
    createNormalTestErr: (error) => ({
      type: actions.NORMAL_TEST_CREATE_ERR,
      error,
    }),
  
    updateNormalTestBegin: () => ({
      type: actions.NORMAL_TEST_UPDATE_BEGIN,
    }),
  
    updateNormalTestSuccess: (data) => ({
      type: actions.NORMAL_TEST_UPDATE_SUCCESS,
      data,
    }),
  
    updateNormalTestErr: (error) => ({
      type: actions.NORMAL_TEST_UPDATE_ERR,
      error,
    }),
  
    deleteNormalTestBegin: () => ({
      type: actions.NORMAL_TEST_DELETE_BEGIN,
    }),
  
    deleteNormalTestSuccess: (data) => ({
      type: actions.NORMAL_TEST_DELETE_SUCCESS,
      data,
    }),
  
    deleteNormalTestErr: (error) => ({
      type: actions.NORMAL_TEST_DELETE_ERR,
      error,
    }),
  };
  
  export default actions;
  